import { supportedCountryCodes } from "const/countryCodes"
import { AZURE_MAPS_KEY } from "env"
import { getLocationType } from "utils/getLocationType"

//https://docs.microsoft.com/en-us/rest/api/maps/search/get-search-address
// grab coordinates from location/address string

export async function fetchLocationCoordinates(location) {
  try {
    const { type, search } = getLocationType(location)

    let queryParams = `&query=${location}&countrySet=${supportedCountryCodes.toString()}&limit=1`

    // Add specific params based on location type
    if (type === "just_coords") {
      // Check for exact "City, State" format (one comma followed by state code)
      const cityStateMatch = location.match(/^([^,]+),\s*([A-Z]{2})$/)
      if (cityStateMatch) {
        // For "City, State" format, use Municipality type
        queryParams += "&entityType=Municipality&typeahead=false"
      }
    } else if (type === "state") {
      // For state-only searches
      queryParams += "&entityType=CountrySubdivision"
    } else if (type === "zip") {
      // For zip code searches
      queryParams += "&entityType=PostalCodeArea"
    }

    const res = await fetch(
      `https://atlas.microsoft.com/search/address/json?format=json&api-version=1.0&subscription-key=${AZURE_MAPS_KEY}${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    const data = await res.json()

    if (data && data.results && data.results.length > 0) {
      const lat = data.results[0].position.lat
      const lon = data.results[0].position.lon
      return { lat, lon }
    }

    if (data.error) {
      throw data.error.message
    }

    return null
  } catch (e) {
    throw new Error(
      `Could not fetch coordinates from search: ${location}: ${JSON.stringify(
        e
      )}`
    )
  }
}

export async function fetchLocationFromCoordinates(coordinates) {
  try {
    let locationName
    const res = await fetch(
      `https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&limit=1&subscription-key=${AZURE_MAPS_KEY}&countrySet=${supportedCountryCodes.toString()}&query=${
        coordinates.latitude
      },${coordinates.longitude}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    const data = await res.json()

    if (data.addresses.length !== 0) {
      const address = data.addresses[0].address
      const hasCity =
        typeof address.municipality !== "undefined" && address.municipality
      const state = address.countrySubdivision
      if (hasCity) {
        locationName = `${hasCity}, ${state}`
      } else {
        locationName = `${state}`
      }
      return locationName
    } else {
      throw new Error("Unable to retrieve user's current location")
    }
  } catch (e) {
    throw new Error(
      `Could not fetch location from coordinates: ${JSON.stringify(
        coordinates
      )}: ${JSON.stringify(e)}`
    )
  }
}
